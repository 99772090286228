<template>
  <div id="nav-3d" v-show="indexVisible">
    <h1 class="main-title">产品360体验</h1>
    <div class="category-group">
      <div class="category">
        <h2 class="category-title">汽车</h2>
        <div class="link-group">
          <a
            class="nav-link"
            href="https://cms.zhinan.tech/api/sites/preview/1317/index.html"
            target="_blank"
          >
            <span>海洋 3D看车</span>
          </a>
          <a
            class="nav-link"
            href="https://cms.zhinan.tech/api/sites/preview/1289/index.html"
            target="_blank"
          >
            <span>腾势 3D看车</span>
          </a>
          <a
            class="nav-link"
            href="https://cms.zhinan.tech/api/sites/preview/1152/index.html"
            target="_blank"
          >
            <span>保时捷 隧道场景模拟驾驶</span>
          </a>
        </div>
      </div>

      <div class="category">
        <h2 class="category-title">手机</h2>
        <div class="link-group">
          <a class="nav-link" href="https://www.oneplus.com/global/open/3d" target="_blank">
            <span>一加Open 360体验</span>
          </a>
          <a
            class="nav-link"
            href="https://www.oneplus.com/uk/oneplus-open?sku=5011103402&_gl=1*176xgr9*_up*MQ..*_ga*NTAwNDM0MzQyLjE3MzYzNDIzMTk.*_ga_2JD4LD71G7*MTczNjM0MjMxOC4xLjAuMTczNjM0MjMxOC4wLjAuNzgzNjAyNTA3"
            target="_blank"
          >
            <span>一加 电商橱窗3D产品展示</span>
          </a>
          <a
            class="nav-link"
            href="https://cms.zhinan.tech/api/sites/preview/1376/index.html"
            target="_blank"
          >
            <span>一加13 360展示</span>
          </a>
          <a class="nav-link" href="https://www.oneplus.in/oneplus-13r" target="_blank">
            <span>一加13R 360橱窗</span>
          </a>
          <a
            class="nav-link"
            href="https://www.oppo.com/en/smartphones/series-find-n/find-n3-flip/3d/"
            target="_blank"
          >
            <span>OPPO Find N3 360展示</span>
          </a>
          <a
            class="nav-link"
            href="https://www.oppo.com/en/smartphones/series-find-n/find-n2-flip/3d/"
            target="_blank"
          >
            <span>OPPO Find N2 360展示</span>
          </a>
        </div>
      </div>

      <div class="category">
        <h2 class="category-title">手表</h2>
        <div class="link-group">
          <!-- <a
            class="nav-link"
            href="https://cms.zhinan.tech/api/sites/preview/1418/index.html"
            target="_blank"
          >
            <span>一加 Watch 2 360展示</span>
          </a> -->
          <a
            class="nav-link"
            href="https://cms.zhinan.tech/api/sites/preview/1422/index.html"
            target="_blank"
          >
            <span>一加 Watch 3 360展示</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <router-view />
</template>

<script>
import Vue, { computed, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: '3d',
  setup() {
    const route = useRoute();
    const indexVisible = computed(() => route.path === '/3d');
    return { indexVisible };
  },
};
</script>

<style>
.main-title {
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: 800;
  color: #1a1a1a;
  margin: 0 0 40px;
  padding-bottom: 20px;
  position: relative;
}

#nav-3d {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px 40px;
}

.category-group {
  display: flex;
  justify-content: space-between;
}

.category {
  margin-bottom: 40px;
  background: white;
  border-radius: 12px;
  padding: 24px;
  margin: 0 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: fit-content;
}

.category-title {
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0 0 20px 0;
  padding-left: 12px;
  border-left: 4px solid #42b983;
}

.link-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
}

.nav-link {
  display: block;
  padding: 16px 24px;
  text-decoration: none;
  color: #2c3e50;
  background: #f8f9fa;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.nav-link:hover {
  background: #42b983;
  color: white;
  transform: translateY(-2px);
}

.nav-link span {
  font-size: 16px;
  font-weight: 500;
}

.router-link-exact-active {
  background: #42b983;
  color: white;
}
</style>

<style lang="stylus">
.relative
  position relative

.absolute
  position absolute
  top 0
  left 0

.fixed
  position absolute
  top 0
  left 0

.full,
.full-w
  width 100%

.full,
.full-h
  height 100%
</style>
